@use '@/styles/main' as *;

.title {
  @include title1;

  line-height: 32px;
  padding: 23px 0;
  color: $font_color_black_grey;

  @include mediumAndBelow {
    border-bottom: 1px solid #9092a333;
    padding: 10px 0;
    margin-bottom: 10px;
  }
}

.trackList {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  column-gap: 50px;

  @include mediumAndBelow {
    flex-direction: column;
  }
}

.emptyStateList {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  column-gap: 50px;
  padding-bottom: 48px;
  border-bottom: 1px solid #9092a333;
  margin-bottom: 50px;

  @include largeAndBelow {
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: unset;
  }
}

.borderTop {
  border-top: 1px solid #9092a333;
  width: calc(50% - 25px);
  padding-top: 23px;

  @include largeAndBelow {
    width: 100%;
  }

  @include mediumAndBelow {
    border-top: unset;
  }
}

.hideEmptyUI {
  @include largeAndBelow {
    display: none;
  }
}
