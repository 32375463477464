
.loader {
    animation: loader-animation ease 1.1s infinite;
    border-radius: 50%;
  }
  
  @keyframes loader-animation {
    0% {
      transform: scale(0);
    }
  
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
  