@use '@/styles/main' as *;

.engagedTitle {
  @include title1;

  line-height: 32px;
  margin-bottom: 25px;

  @include mediumAndBelow {
    margin-bottom: 15px;
  }
}

.noData {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 36px 26px 43px 56px;
  background: $color_white_70;
  border: 1px solid $color_white_100;
  border-radius: 16px;
  position: relative;

  @include smallAndBelow {
    padding: 20px;
    height: 230px;
  }
}

.chartImage {
  width: 64%;
  position: absolute;
  height: 240px;
  max-width: 540px;
  top: 36px;
  right: 26px;

  @include smallAndBelow {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 190px;
    width: 80%;
  }
}

.noDataWrapper {
  max-width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 10;

  @include largeAndBelow {
    height: 100%;
    max-width: 50%;
  }

  @include smallAndBelow {
    max-width: 80%
  }
}

.title {
  @include font_h4_large;

  line-height: 24px;
  color: $font_color_black_grey;
  font-weight: $semibold;
  margin-bottom: 8px;
}

.subTitle {
  @include font_body;

  line-height: 19px;
  color: $font_color_dark_grey;
}
