@use '@/styles/main' as *;

.card {
  width: 48%;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.backgroundCard {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.85;
}

.contentArea {
  padding: 15px 25px 40px;
  color: $font_color_w100;
  height: 100%;
  position: relative;

  @include mediumAndBelow {
    padding: 9px 14px 24px;
  }
}

.title {
  @include custom_font;

  font-weight: $bold;
  font-size: 54px;
  line-height: 63px;

  @include smallAndBelow {
    font-size: 48px;
    line-height: 53px;
  }
}

.subTitle {
  @include font_h3_large;

  letter-spacing: 0.01em;
  font-weight: $semibold;
  line-height: 29px;

  @include mediumAndBelow {
    @include font_h4;

    line-height: 19px;
    font-weight: $semibold;
  }
}
