@use '@/styles/main' as *;

.container {
  width: calc(50% - 25px);

  @include largeAndBelow {
    width: 100%;
  }
}

.title {
  @include title1;

  padding: 20px 0;
  line-height: 32px;
  font-weight: $semibold;
  color: $font_color_black_grey;
  border-bottom: 1px solid #9092a333;
  margin-bottom: 20px;

  @include mediumAndBelow {
    padding: 10px 0;
  }
}

.topicList {
  display: flex;
  gap: 30px;
  padding-right: 10px;
  flex-direction: column;

  @include mediumAndBelow {
    gap: 15px;
  }
}
