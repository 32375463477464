@use '@/styles/main' as *;

.customeTooltip {
  background: linear-gradient(0deg, $color_white_100, $color_white_100),
    linear-gradient(
      90deg,
      #fff4fd 0.81%,
      #f4f5ff 28.06%,
      #ecf8ff 69%,
      #eefffc 100%
    );
  border: unset;
  border-radius: 8px;
  box-shadow: 0 8px 50px rgba(43 42 107 / 20%);
  text-align: center;
  padding: 10px 15px;

  &:hover {
    border: unset;
  }
}

.tooltipLabel {
  @include font_h4;

  color: $color_text_general;
  line-height: 22px;
  letter-spacing: 0.1px;
  font-weight: $regular;
}

.tooltipValue {
  @include title1;
  
  color: $color_text_general;
  font-weight: $semibold;
  letter-spacing: 0.1px;
}
