@use '@/styles/main' as *;

.navItem {
  @include chat_input;

  align-items: center;
  color: $font_color_black_grey;
  display: flex;
  gap: 20px;
  padding: 12px 15px;

  &:hover {
    background: $color_white_50;
    box-shadow: 0 1px 20px rgba(31 51 9 / 10%);
    border-radius: 8px;
  }

  @include mediumAndBelow {
    @include font_h3_large;

    line-height: 28px;
    font-family: $semibold;
    padding: 22px 25px;
  }
}

.activeItem {
  background: $color_white_100;
  box-shadow: 0 1px 20px rgba(31 51 9 / 10%);
  border-radius: 8px;
  font-weight: $semibold;
}
