@use '@/styles/main' as *;

.darkmodebackground {
  height: 100vh;
  object-fit: cover;
  position: fixed !important;
  width: 100%;
  z-index: -1;
}

.auraLogo {
  position: relative;
}

.auraContainer {
  align-items: center;
  display: flex;
  padding-left: 100px;
  padding-top: 35px;
}

.auraText {
  @include font_body;

  line-height: 19px;
  margin-left: 15px;
}
