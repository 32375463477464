@use '@/styles/main' as *;

.container {
  display: flex;
  width: calc(50% - 25px);
  padding: 12px 15px 15px 0;
  gap: 17px;
  border-top: 1px solid #9092a333;

  @include mediumAndBelow {
    padding: 10px 10px 10px 0;
    width: 100%;
    border: unset;
  }
}

.topic {
  @include font_subtitle2;

  color: $font_color_grey;
  margin-bottom: 2px;
}

.trackImage {
  border-radius: 6px;
}

.trackInfoWrapper {
  flex: 1;
}
