$smallWidth: 576px;
$mediumWidth: 768px;
$largeWidth: 992px;
$xLargeWidth: 1200px;

@mixin xLargeAndBelow {
  @media (max-width: #{ $xLargeWidth }) {
    @content;
  }
}

@mixin largeAndBelow {
  @media (max-width: #{ $largeWidth }) {
    @content;
  }
}

@mixin largeOnly {
  @media (min-width: #{ $largeWidth }) and (max-width: #{ $xLargeWidth - 1px }) {
    @content;
  }
}

@mixin mediumAndBelow {
  @media (max-width: #{ $mediumWidth }) {
    @content;
  }
}

@mixin mediumOnly {
  @media (min-width: #{ $mediumWidth }) and (max-width: #{ $largeWidth - 1px }) {
    @content;
  }
}

@mixin smallAndBelow {
  @media (max-width: #{ $smallWidth }) {
    @content;
  }
}
