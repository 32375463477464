@use '@/styles/main' as *;

.contentWithNav {
  margin-left: 230px;

  @include mediumAndBelow {
    margin-left: 0;
    margin-top: 65px;
  }
}
