@use '@/styles/main' as *;

.noData {
  position: relative;
  min-height: 240px;
  width: 100%;
  display: flex;
}

.emptyImage {
  width: 260px;
  position: absolute;
  height: 230px;
  top: 0;
  right: 20px;
  z-index: -1;
}

.alignLeft {
  left: 0;
  right: unset;
}

.noDataWrapper {
  max-width: 57%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;

  @include mediumAndBelow{
    max-width: 37%;
  }

  @include smallAndBelow{
    max-width: 69%;
  }
}

.noDataTitle {
  @include font_h4_large;

  line-height: 24px;
  color: $font_color_black_grey;
  font-weight: $semibold;
  margin-bottom: 8px;
}

.noDataSubTitle {
  @include font_body;

  line-height: 19px;
  color: $font_color_dark_grey;
}
