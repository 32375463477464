@use '@/styles/main' as *;

.navMain {
  background: $color_white_50;
  border: 1px solid $color_white_100;
  border-top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 230px;

  @include mediumAndBelow {
    position: relative;
    width: 100%;
    z-index: 999;
  }
}

.head {
  padding: 50px 38px 22px;
  border-bottom: 1px solid rgba(144 146 163 / 15%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mediumAndBelow {
    backdrop-filter: blur(50px);
    border: unset;
    height: 60px;
    position: fixed;
    width: 100%;
    background: $color_white_50;
    padding: 15px 23px 12px;
  }
}

.iconArea {
  align-items: center;
  display: flex;
  gap: 16px;

  @include mediumAndBelow {
    gap: 9px;
  }
}

.title {
  @include font_h4;

  color: #151616;
  margin: 0;
}

.navContainer {
  padding: 35px 30px 0 35px;
  height: calc(100% - 110px);

  @include mediumAndBelow {
    height: 0;
    margin-top: 60px;
    position: fixed;
    right: 0;
    width: 100%;
    backdrop-filter: blur(50px);
    background: $color_white_50;
    overflow: hidden;
    padding: 0 20px;
    transition: height 0.6s ease-in-out, opacity 0.6s ease-in-out;
  }
}

.navItemList {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  @include mediumAndBelow {
    gap: 12px;
    transition: opacity 0.6s ease-in-out;
    opacity: 0;
    padding-top: 15px;
  }
}

.navItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.openNavMobile {
  height: calc(100vh - 60px);
}

.openNavBarList {
  opacity: 1;
}

.logoutButton {
  @include chat_input;

  align-items: center;
  color: $font_color_black_grey;
  display: flex;
  gap: 20px;
  padding: 12px 15px;
  margin-bottom: 10px;

  &:hover {
    background: $color_white_50;
    box-shadow: 0 1px 20px rgba(31 51 9 / 10%);
    border-radius: 8px;
  }

  @include mediumAndBelow {
    @include font_h3_large;

    line-height: 28px;
    font-family: $semibold;
    padding: 22px 25px;
  }
}
