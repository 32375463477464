@use '@/styles/main' as *;

.gradientBarWrapper {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.barArea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 98%;
}

.title {
  @include font_h4;

  color: $color_text_general;
  font-weight: $semibold;
  line-height: 22px;
}

.progress {
  @include font_body;

  color: $color_text_general;
  line-height: 19px;
  opacity: 0.6;
}

.progressBar {
  border-radius: 10px;
  opacity: 0.7;
}
