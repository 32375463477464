@use '@/styles/main' as *;

.container {
  padding: 45px 40px 0 60px;

  @include xLargeAndBelow {
    padding: 45px 60px 0;
  }

  @include mediumAndBelow {
    padding: 15px 20px 0;
  }
}

.wrapper {
  max-width: 1085px;
}

.welcomeUserWrapper {
  background: rgba(255 255 255 / 75%);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 18px 20px 23px 23px;
  margin-bottom: 65px;

  @include mediumAndBelow {
    padding: 15px;
    margin-bottom: 30px;
  }
}

.welcomeUserContent {
  display: flex;
  gap: 18px;
}

.companyName {
  @include font_body2;

  color: $font_color_dark_grey;
  line-height: 17px;
  opacity: 0.6;

  @include smallAndBelow {
    @include font_subtitle2;
  }
}

.userName {
  @include font_h3;

  color: $font_color_black_grey;
  font-weight: $semibold;
  line-height: 27px;
  margin: 6px 0 1px;

  @include smallAndBelow {
    @include font_h4;

    font-weight: $semibold;
    line-height: 22px;
    margin: 3px 0 0;
  }
}

.greet {
  @include font_body;

  color: $font_color_dark_grey;
  line-height: 19px;

  @include smallAndBelow {
    @include font_subtitle2;
  }
}

.dashboardTitle {
  color: $font_color_black_grey;
  font-weight: $semibold;
  font-size: 34px;
  line-height: 41px;
  margin: 0 0 32px;

  @include smallAndBelow {
    margin: 0 0 16px;
  }
}

.filterbyTexr {
  @include font_h4;

  line-height: 22px;
  color: $font_color_black_grey;
  margin: 10px 0 20px;
  font-weight: $regular;
}

.filterArea {
  display: flex;
  gap: 8px;
  margin-bottom: 30px;

  @include mediumAndBelow {
    padding-bottom: 20px;
    border-bottom: 1px solid #9092a333;
    margin-bottom: 20px;
  }
}

.stateCardArea {
  display: flex;
  gap: 30px;
  margin-bottom: 42px;

  @include mediumAndBelow {
    gap: 10px;
    margin-bottom: 35px;
  }
}

.engagedTitle {
  @include title1;

  line-height: 32px;
  margin-bottom: 25px;

  @include mediumAndBelow {
    margin-bottom: 15px;
  }
}

.choosenTopicArea {
  margin: 45px 0 39px;
  gap: 50px;
  display: flex;
  padding-bottom: 30px;
  border-bottom: 1px solid #9092a333;

  @include largeAndBelow {
    flex-direction: column;
    gap: 35px;
    border-bottom: unset;
    padding-bottom: 0;
    margin: 25px 0 20px;
  }
}

.filtersWrapper {
  @include mediumAndBelow {
    position: sticky;
    top: 59px;
    padding-top: 20px;
  }
}

.filterFixTop {
  background: $color_white_50;
  backdrop-filter: blur(50px);
  z-index: 10;
  margin: 0 -20px;
  padding: 10px 20px 0;
}

.auraRing {
  height: 70px;

  @include smallAndBelow {
    height: 66px;
  }
}
